import M from 'materialize-css';

let instance;

class store {
    constructor() {
        if (!instance) {
            instance = this;
            instance.init();
        } else
            return instance;

        //this.host = 'http://platform.kks'; //пустой будет обращаться к locallhost
    }

    init() {
        this.allAddress = [];

        const localGet = this.getUrlParam("lang");
        this.local = localGet !== "" ? localGet : "UA";
        this.globalTranslations = {};
        this.needLoadRegistr = false;
        this.phoneNum = "+38";

        // загрузить локализацию
        {
            let dataObj = {
                type: 'getSiteData',
                name: 'globalTranslations'
            };
            this.sendAjaxNew(dataObj, (data => {
                if (data.globalTranslations !== '')
                    this.globalTranslations = JSON.parse(data.globalTranslations);
            }));
        }


        window.onresize = this.changeMainBoxHeight;

        window.addEventListener('scroll', function () {

            //console.log(window.scrollY); // вся ширина окна
            // console.log(document.documentElement.clientHeight); // ширина минус прокрутка
            const pos = (window.scrollY / (document.documentElement.clientHeight / 100));
            // console.log(pos + "%");
            //  const posY = parseInt(window.scrollY / 10);
            document.getElementById('back').style.backgroundPosition = "0 " + pos + "%";
        });
    }

    /**
     * Меняет минимальную высоту контейнера 'mainBox' в зависимости от размера окна браузера пользователя
     */
    changeMainBoxHeight() {
        const mainBox = document.getElementById('mainBox');
        if (mainBox)
            mainBox.style.minHeight = (document.documentElement.clientHeight - 175) + "px";
    }


    isset() {
        if (arguments.length === 0) return false;
        let buff = arguments[0];
        for (let i = 0; i < arguments.length; i++) {
            if (typeof (buff) === 'undefined') return false;
            buff = buff[arguments[i + 1]];
        }
        return true;
    };

    getUrlParam(name) {
        if (name = (new RegExp('[?&]' + encodeURIComponent(name) + '=([^&]*)')).exec(window.location.search))
            return decodeURIComponent(name[1]);
        else return "";
    }

    getUrlParamAll() {//todo переписать
        let urlVar = window.location.search; // получаем параметры из урла
        let arrayVar = []; // массив для хранения переменных
        let valueAndKey = []; // массив для временного хранения значения и имени переменной
        let resultArray = []; // массив для хранения переменных
        arrayVar = (urlVar.substr(1)).split('&'); // разбираем урл на параметры
        if (arrayVar[0] == "") return false; // если нет переменных в урле
        for (let i = 0; i < arrayVar.length; i++) { // перебираем все переменные из урла
            valueAndKey = arrayVar[i].split('='); // пишем в массив имя переменной и ее значение
            resultArray[valueAndKey[0]] = valueAndKey[1]; // пишем в итоговый массив имя переменной и ее значение
        }
        return resultArray;
    }

    setLocation(type, value) {
        let allParam = this.getUrlParamAll();
        let url = '';
        let flag = true;
        for (const key in allParam) {
            if (key === type) {
                allParam[key] = value;
                flag = false;
            }
            if (url === '') url = '?' + key + '=' + allParam[key];
            else url += '&' + key + '=' + allParam[key];
        }

        if (url === '') url = '?' + type + '=' + value;
        else if (flag) url += '&' + type + '=' + value;

        try {
            window.history.pushState(null, null, url);
            return;
        } catch (e) {
        }
        window.location.hash = url;
    }

    smoothScrollTo = (function () {
        let timer, start, factor;

        return function (target, duration) {
            let offset = window.pageYOffset,
                delta = target - window.pageYOffset; // Y-offset difference
            duration = duration || 1000;              // default 1 sec animation
            start = Date.now();                       // get start time
            factor = 0;

            if (timer) {
                clearInterval(timer); // stop any running animations
            }

            function step() {
                let y;
                factor = (Date.now() - start) / duration; // get interpolation factor
                if (factor >= 1) {
                    clearInterval(timer); // stop animation
                    factor = 1;           // clip to max 1.0
                }
                y = factor * delta + offset;
                window.scrollBy(0, y - window.pageYOffset);
            }

            timer = setInterval(step, 10);
            return timer;
        };
    }());

    modalLogin() {
        console.log("pre modalLogin");
        debugger
        try {
            document.getElementById('loginDiv').style.display = 'block';
            document.getElementById('regDiv').style.display = 'none';
            document.getElementById('successRegDiv').style.display = 'none';

            let elems = document.querySelectorAll('.modal');
            M.Modal.init(elems);

            let instance = M.Modal.getInstance(elems[0]);
            instance.open();
        } catch (e) {
            console.log(e);
            window.location.href = '/';
        }
    }

    loginExit() {
        let dataObj = {
            type: "logout"
        };
        this.sendAjaxNew(dataObj, (content => {
            window.location.reload(true);
        }));
    }

    changeViewInfo(flag) {
        let dataObj = {
            href: "cabinet/conf",
            type: 'cross-domain-request',
            data: {
                displayInfo: flag
            }
        };

        this.sendAjaxNew(dataObj, (content => {
            console.log(content);
            if (content === "0")
                window.location.href = "/?nav=3&lang=" + this.local;
            else
                M.toast({html: "Ошибка. Повторите попытку позже.", classes: 'toast-Error'});
        }));
    }


    checkPassword(password) {
        console.log(password);
        // event.srcElement.value
        const s_letters = "qwertyuiopasdfghjklzxcvbnm"; // Буквы в нижнем регистре
        const b_letters = "QWERTYUIOPLKJHGFDSAZXCVBNM"; // Буквы в верхнем регистре
        const digits = "0123456789"; // Цифры
        const specials = "!@#$%^&*()_-+=\|/.,:;[]{}"; // Спецсимволы

        let is_s = false; // Есть ли в пароле буквы в нижнем регистре
        let is_b = false; // Есть ли в пароле буквы в верхнем регистре
        let is_d = false; // Есть ли в пароле цифры
        let is_sp = false; // Есть ли в пароле спецсимволы

        for (let i = 0; i < password.length; i++) {
            //Проверяем каждый символ пароля на принадлежность к тому или иному типу
            if (!is_s && s_letters.indexOf(password[i]) != -1) is_s = true;
            else if (!is_b && b_letters.indexOf(password[i]) != -1) is_b = true;
            else if (!is_d && digits.indexOf(password[i]) != -1) is_d = true;
            else if (!is_sp && specials.indexOf(password[i]) != -1) is_sp = true;
        }

        let rating = 0;
        let text = "";
        if (is_s) rating++; // Если в пароле есть символы в нижнем регистре, то увеличиваем рейтинг сложности
        if (is_b) rating++; // Если в пароле есть символы в верхнем регистре, то увеличиваем рейтинг сложности
        if (is_d) rating++; // Если в пароле есть цифры, то увеличиваем рейтинг сложности
        if (is_sp) rating++; // Если в пароле есть спецсимволы, то увеличиваем рейтинг сложности
        // Далее идёт анализ длины пароля и полученного рейтинга, и на основании этого готовится текстовое описание сложности пароля
        if (password.length < 6 && rating < 3) text = this.globalTranslations[this.local].passwordComplexity[1];
        else if (password.length < 6 && rating >= 3) text = this.globalTranslations[this.local].passwordComplexity[2];
        else if (password.length >= 8 && rating < 3) text = this.globalTranslations[this.local].passwordComplexity[2];
        else if (password.length >= 8 && rating >= 3) text = this.globalTranslations[this.local].passwordComplexity[3];
        else if (password.length >= 6 && rating === 1) text = this.globalTranslations[this.local].passwordComplexity[1];
        else if (password.length >= 6 && rating > 1 && rating < 4) text = this.globalTranslations[this.local].passwordComplexity[2];
        else if (password.length >= 6 && rating === 4) text = this.globalTranslations[this.local].passwordComplexity[3];

        document.getElementById('passwordLable').innerHTML = text;
    }

    nextAddress(event, isReport) {
        console.log('nextAddress check');
        if (event.key === 'Enter') {
            console.log('nextAddress Enter');
            event.currentTarget.blur();
        } else if (event.key !== "ArrowUp" && event.key !== "ArrowDown" && event.key !== "ArrowLeft" && event.key !== "ArrowRight")
            this.updateAddress(event.currentTarget, isReport);
    }

    updateAddress(el, isReport) {
        console.log("updateAddress");
        const cur = el.getAttribute("data-cur");
        const report = (isReport ? "-report" : "");
        let elValue = '';
        if (this.allAddress["M." + cur] && this.allAddress["M." + cur].count < 10) {
            let path = "";
            switch (cur) {
                case 'cities':
                    path = 'cabinet/address/cities';
                    elValue = document.getElementById('autocomplete-input-cities' + report).value;
                    break;
                case 'streets': {
                    const prevValue = document.getElementById('autocomplete-input-cities' + report).value;
                    for (let key in this.allAddress['cities']) {
                        if (this.allAddress['cities'][key].name === prevValue) {
                            path = 'cabinet/address/cities/' + this.allAddress['cities'][key].uuid + '/streets';
                            elValue = document.getElementById('autocomplete-input-streets' + report).value;
                            break;
                        }
                    }
                }
                    break;
                case 'buildings': {
                    const prevValue = document.getElementById('autocomplete-input-streets' + report).value;
                    for (let key in this.allAddress['streets']) {
                        if (this.allAddress['streets'][key].name === prevValue) {
                            path = 'cabinet/address/streets/' + this.allAddress['streets'][key].uuid + '/buildings';
                            elValue = document.getElementById('autocomplete-input-buildings' + report).value;
                            break;
                        }
                    }
                }
                    break;
                case 'apartments': {
                    const prevValue = document.getElementById('autocomplete-input-buildings' + report).value;
                    for (let key in this.allAddress['buildings']) {
                        if (this.allAddress['buildings'][key].name === prevValue) {
                            path = 'cabinet/address/buildings/' + this.allAddress['buildings'][key].uuid + '/apartments';
                            elValue = document.getElementById('autocomplete-input-apartments' + report).value;
                            break;
                        }
                    }
                }
            }
            let dataObj = {
                type: 'cross-domain-request',
                href: path,
                data: 'name=' + encodeURIComponent(elValue),
                method: 'GET'
            };
            this.sendAjaxNew(dataObj, (data => {
                console.log(data.content);
                const dataContent = JSON.parse(data.content);
                if (dataContent.state.message === "OK") {
                    this.allAddress[cur] = dataContent.data.table;
                    let newData = [];
                    for (let ikey in dataContent.data.table) {
                        const keyName = dataContent.data.table[ikey].name;
                        newData[keyName] = null;
                    }
                    this.allAddress["M." + cur].updateData(newData);
                    this.allAddress["M." + cur].open();
                }
            }));
        }
    }

    changeAddress(el, isReport) {
        console.log("changeAddress " + el.value);
        const cur = el.getAttribute("data-cur");
        const last = el.getAttribute("data-last");
        const report = (isReport ? "-report" : "");
        for (let key in this.allAddress[cur]) {
            if (this.allAddress[cur][key].name === el.value) {
                el.classList.remove("invalid");
                el.classList.add("valid");

                if (last !== '') {
                    let dataObj = {
                        type: 'cross-domain-request',
                        href: 'cabinet/address/' + cur + '/' + this.allAddress[cur][key].uuid + '/' + last,
                        data: '',
                        method: 'GET'
                    };
                    this.sendAjaxNew(dataObj, (data => {
                        const dataContent = JSON.parse(data.content);
                        console.log(dataContent);
                        if (dataContent.state.message === "OK") {
                            let newData = [];
                            for (let ikey in dataContent.data.table) {
                                const qwe = dataContent.data.table[ikey].name;
                                newData[qwe] = null;
                            }
                            this.allAddress[last] = dataContent.data.table;
                            let elem = document.getElementById('autocomplete-input-' + last + report);
                            this.allAddress["M." + last] = M.Autocomplete.init(elem, {data: newData});
                            elem.disabled = false;
                            elem.focus();
                        }
                        document.getElementById('regDiv').style.display = 'block';
                        document.getElementById('loginDiv').style.display = 'none';
                    }));
                }
                return;
            }
        }
        el.classList.remove("valid");
        el.classList.add("invalid");
        if (last !== '')
            document.getElementById('autocomplete-input-' + last + report).disabled = true;
    }

    getReport(context) {
        const regCode = document.getElementById('regCodeReport');
        const city = document.getElementById('autocomplete-input-cities-report');
        const street = document.getElementById('autocomplete-input-streets-report');
        const build = document.getElementById('autocomplete-input-buildings-report');
        const apartment = document.getElementById('autocomplete-input-apartments-report');

        if (regCode.value === '' ||
            !city.classList.contains('valid') || !street.classList.contains('valid') || !build.classList.contains('valid') || !apartment.classList.contains('valid')) {
            M.toast({html: 'Необходимо корректно заполнить все поля', classes: 'toast-Error'});
            return;
        } else M.Toast.dismissAll();

        let uuid;
        for (let key in this.allAddress['apartments']) {
            if (this.allAddress['apartments'][key].name === apartment.value) {
                uuid = this.allAddress['apartments'][key].uuid;
            }
        }

        let dataObj = {
            type: 'cross-domain-request',
            href: 'cabinet/reg-openly',
            data: 'addressCode=' + uuid + "&someCode=" + regCode.value,
            method: 'POST'
        };
        this.sendAjaxNew(dataObj, (data => {
            const dataContent = JSON.parse(data.content);
            if (dataContent.code === 0) {
                context.loadContent();
            } else
                M.toast({html: dataContent.message, classes: 'toast-Error'});

        }));
    }

    loadRegistr(isReport) {
        console.log("loadRegistr");
        const report = (isReport ? "-report" : "");
        let dataObj = {
            type: 'cross-domain-request',
            href: 'cabinet/address/cities',
            data: 'name=' + encodeURIComponent("м. Краматорськ"),
            method: 'GET'
        };

        document.getElementById("autocomplete-input-cities").value = "м. Краматорськ";

        this.sendAjaxNew(dataObj, (data => {
            if (data.content == "") {
                M.toast({
                    html: "<p>На данный момент регистрация невозможна.<br>Пожалуйста попробуйте позже.</p>",
                    classes: 'toast-Error'
                });
                return;
            }

            const dataContent = JSON.parse(data.content);
            console.log(dataContent);
            if (dataContent.state.code === 0) {
                let newData = [];
                for (let key in dataContent.data.table) {
                    const keyName = dataContent.data.table[key].name;
                    newData[keyName] = null;
                }
                this.allAddress['cities'] = dataContent.data.table;
                let elem = document.getElementById('autocomplete-input-cities' + report);
                this.allAddress["M.cities"] = M.Autocomplete.init(elem, {data: newData});

                setTimeout(() => {
                    const city = document.getElementById('autocomplete-input-cities');
                    city.focus();
                    city.blur();
                    //this.changeAddress(document.getElementById('autocomplete-input-cities'))
                }, 400);
            }
            if (report === '') {
                document.getElementById('regDiv').style.display = 'block';
                document.getElementById('loginDiv').style.display = 'none';
            }
        }));
    }

    goRegistr() {
        const login = document.getElementById('loginNew');
        const regCode = document.getElementById('regCode');
        const email = document.getElementById('email');
        const city = document.getElementById('autocomplete-input-cities');
        const street = document.getElementById('autocomplete-input-streets');
        const build = document.getElementById('autocomplete-input-buildings');
        const apartment = document.getElementById('autocomplete-input-apartments');
        const phone = document.getElementById('phoneNum');

        if (login.value === '' || regCode.value === '' || !email.classList.contains('valid') ||
            !city.classList.contains('valid') || !street.classList.contains('valid') || !build.classList.contains('valid') || !apartment.classList.contains('valid')) {
            M.toast({html: 'Необходимо корректно заполнить все поля', classes: 'toast-Error'});
            return;
        } else M.Toast.dismissAll();

        let uuid;
        for (let key in this.allAddress['apartments']) {
            if (this.allAddress['apartments'][key].name === apartment.value) {
                uuid = this.allAddress['apartments'][key].uuid;
            }
        }

        let dataObj = {
            type: 'cross-domain-request',
            href: 'cabinet/reg',
            data: 'addressCode=' + uuid + '&personalCode=' + login.value + '&email=' + email.value + "&someCode=" + regCode.value + "&phone=" + phone.value,
            method: 'POST'
        };
        this.sendAjaxNew(dataObj, (data => {

            const dataContent = JSON.parse(data.content);
            if (dataContent.state.code === 0) {
                document.getElementById('successRegDiv').style.display = 'block';
                document.getElementById('regDiv').style.display = 'none';
            } else
                M.toast({html: dataContent.state.message, classes: 'toast-Error'});
        }));
    }

    loginCheck(context) {
        console.log("loginCheck");
        console.log(context);
        const login = document.getElementById('login').value;
        const pass = document.getElementById('password').value;
        let dataObj = {
            type: 'cross-domain-request',
            href: 'auth/login',
            data: 'uid=' + login + '&pwd=' + pass,
            method: 'POST'
        };

        this.sendAjaxNew(dataObj, (data => {
            debugger;
            if (data.content === 0)
                document.location.href = "/?nav=3&lang=" + this.local;
            else {
                if (data.content === "") {
                    M.toast({
                        html: "<p>На даний момент авторизація неможлива.<br>Спробуйте пізніше.</p>",
                        classes: 'toast-Error'
                    });
                } else
                    M.toast({
                        html: "<p>Помилка авторизації.<br>Невірні пара логін/пароль.<br>Спробуйте ще раз.</p>",
                        classes: 'toast-Error'
                    });
            }
        }));

    }


    sendAjax(dataObj, func, sendTo) {
        dataObj.tabID = sessionStorage.tabID;

        let ajax = new XMLHttpRequest();
        ajax.open("POST", "php/" + sendTo + ".php", true);
        ajax.setRequestHeader('accept', 'application/json');
        ajax.setRequestHeader("content-type", "application/json");
        ajax.send(JSON.stringify(dataObj));
        ajax.onreadystatechange = function () {
            if (ajax.readyState === 4 && ajax.status === 200) {
                func(ajax.responseText);
            }
        }
    }

    sendAjaxNew(dataObj, func) {
        dataObj.tabID = sessionStorage.tabID;

        let ajax = new XMLHttpRequest();
        ajax.open("POST","/api/", true);
/*        ajax.setRequestHeader('Accept', 'application/json');
        ajax.setRequestHeader('Access-Control-Allow-Origin', '*');
        ajax.setRequestHeader("content-type", "application/x-www-form-urlencoded");*/
        ajax.setRequestHeader("content-type", "multipart/form-data");

        ajax.send(JSON.stringify(dataObj));
        ajax.onreadystatechange = function () {
            if (ajax.readyState === 4 && ajax.status === 200) {
                const res = JSON.parse(ajax.responseText);

                console.log("sendAjaxNew res: ", dataObj.type, res);

                if (res.msg === "Success")
                    func(res.data);
            }
        }
    }
}

export default store;