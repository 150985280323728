
import React from "react";
import Store from '../../js/store.js';

const store = new Store();

export default function ViewInfo(props) {
    let htmlInfo = (<div/>);
    const info = [];

    if (props.info !== '' && props.info !== null) {
        let responsible, address, areaTotal, numberTenants, personalCode;

        try {
            if (store.isset(props.info.responsible)) {
                if (props.info.responsible.name !== "") {
                    responsible =
                        <p className={"col s5"}>
                            <strong>{store.globalTranslations[store.local].owner}: </strong>{props.info.responsible.name}
                        </p>;
                    info.push({
                        name: store.globalTranslations[store.local].owner,
                        value: props.info.responsible.name
                    });
                }
            }
            if (store.isset(props.info.personalCode) && props.info.personalCode !== '') {
                personalCode =
                    <p className={"col s5"}>
                        <strong>{store.globalTranslations[store.local].personalAccNum}: </strong>{props.info.personalCode}
                    </p>;
                info.push({
                    name: store.globalTranslations[store.local].personalAccNum,
                    value: props.info.personalCode
                });
            }
            if (store.isset(props.info.address) && props.info.address.name !== '') {
                address =
                    <p className={"col s5"}>
                        <strong>{store.globalTranslations[store.local].address}: </strong>{props.info.address.name}
                    </p>;
                info.push({
                    name: store.globalTranslations[store.local].address,
                    value: props.info.address.name
                });
            }
            if (store.isset(props.info.areaTotal) && props.info.areaTotal !== '') {
                areaTotal =
                    <p className={"col s5"}>
                        <strong>{store.globalTranslations[store.local].areaTotal}: </strong>{props.info.areaTotal}
                    </p>;
                info.push({
                    name: store.globalTranslations[store.local].areaTotal,
                    value: props.info.areaTotal
                });
            }
            if (store.isset(props.info.numberTenants) && props.info.numberTenants !== '') {
                numberTenants =
                    <p className={"col s5"}>
                        <strong>{store.globalTranslations[store.local].numberTenants}: </strong>{props.info.numberTenants}
                    </p>;
                info.push({
                    name: store.globalTranslations[store.local].numberTenants,
                    value: props.info.numberTenants
                });
            }
            let checkView = (<div></div>);

            if (props.mode) {
                if (props.info.responsible === '' || props.info.responsible.name === '')
                    checkView = <p className={"col s6"}><a onClick={() => {
                        store.changeViewInfo(true)
                    }} className={"waves-effect waves-light active-nav btn btnInfo"}>Показати поле</a></p>;
                else
                    checkView = <p className={"col s6"}><a onClick={() => {
                        store.changeViewInfo(false)
                    }} className={"waves-effect waves-light active-nav btn btnInfo"}>Сховати поле</a></p>;
            }

            htmlInfo = (<div className={'d_content scroll z-depth-3'}>
                <h2>{store.globalTranslations[store.local].generalInfo}</h2>
                <div className={"row"}>{personalCode}</div>
                <div className={"row"}>{responsible}{checkView}</div>
                <div className={"row"}>{address}</div>
                <div className={"row"}>{areaTotal}</div>
                <div className={"row"}>{numberTenants}</div>
            </div>);
        } catch (e) {
            console.log("данные скрыты", e);
            if (props.mode) {
                const checkView = <p className={"col s6"}><a onClick={() => {
                    store.changeViewInfo(true)
                }} className={"waves-effect waves-light active-nav btn btnInfo"}>Показати поле</a></p>;
                htmlInfo = (<div className={'d_content scroll z-depth-3'}>
                    <h2>{store.globalTranslations[store.local].generalInfo}</h2>
                    <div className={"row"}>{checkView}</div>
                </div>);
            } else
                htmlInfo = (<div/>);
        }
    }
    
    return [htmlInfo, info]
}