import React from "react";
import Store from '../../js/store.js';
import ContentBalance from "../functions/contentBalance";

const store = new Store();

export default class ViewBalance extends React.Component {
    constructor(props) {
        super();

        //const urlPay = 'https://next.privat24.ua/payments/form/{"token":"4713e5cd-721a-4471-a8cc-7852993a","personalAccount":"' + (this.state.uid) + '"}';
        this.urlPay = 'https://next.privat24.ua/payments/form/{"companyID":"1225048","form":{"query":"40872929","queryId":"3367864"}}';

        console.log("ViewBalance constructor");
    }

    render() {
        let date = new Date;
        let curYear = date.getFullYear();
        let yearsArr = [];
        for(curYear; curYear >= 2016; curYear--)
            yearsArr.push(curYear)


        let i = 0;
        console.log("ViewBalance render");

        return (<div className={'d_content z-depth-3'} style={{minHeight: 600}}>
            <div className={"row"}>
                <div className={"input-field col s7"}>
                    <h2>{store.globalTranslations[store.local].nameBalance}</h2>
                </div>
                <div className="input-field col s4">
                    <a className={"waves-effect waves-light btn btnPay btnPayPersonal"} href={this.urlPay}
                       target="_blank">
                        Сплатити по особ. рах.
                    </a>
                </div>
                <div className={"input-field col s1"}>
                    <a className={"print_btn print_btn_visible btn waves-effect waves-light"} id="toPrinter"
                       target={"_black"}/>
                </div>
            </div>
            <div className={"row"}>
                <div className={"input-field col s6"}>
                    <select id='yearBalance' onChange={() => {
                        this.props.handleUpdateContent("cabinet/balance")
                    }} defaultValue={this.props.balance.year}>
                        <option disabled>{store.globalTranslations[store.local].year}</option>
                        {
                            yearsArr.map(year => {
                                return (
                                    <option value={year+''}>{year}</option>
                                )
                            })
                        }
                    </select>
                    <label>{store.globalTranslations[store.local].reportingPeriod}</label>
                </div>
                <div className={"input-field col s6"}>
                    <select id='monthBalance' onChange={() => {
                        this.props.handleUpdateContent("cabinet/balance")
                    }} defaultValue={this.props.balance.month}>
                        <option disabled>{store.globalTranslations[store.local].month}</option>
                        <option value="1">{store.globalTranslations[store.local].monthName[0]}</option>
                        <option value="2">{store.globalTranslations[store.local].monthName[1]}</option>
                        <option value="3">{store.globalTranslations[store.local].monthName[2]}</option>
                        <option value="4">{store.globalTranslations[store.local].monthName[3]}</option>
                        <option value="5">{store.globalTranslations[store.local].monthName[4]}</option>
                        <option value="6">{store.globalTranslations[store.local].monthName[5]}</option>
                        <option value="7">{store.globalTranslations[store.local].monthName[6]}</option>
                        <option value="8">{store.globalTranslations[store.local].monthName[7]}</option>
                        <option value="9">{store.globalTranslations[store.local].monthName[8]}</option>
                        <option value="10">{store.globalTranslations[store.local].monthName[9]}</option>
                        <option value="11">{store.globalTranslations[store.local].monthName[10]}</option>
                        <option value="12">{store.globalTranslations[store.local].monthName[11]}</option>
                    </select>
                </div>
            </div>
            <div className={"row scroll"} id={"balanceNode"}>
                <table className={"balanceTable"}>
                    <tbody>
                    <tr>
                        <th><p>{store.globalTranslations[store.local].service}</p></th>
                        <th><p>{store.globalTranslations[store.local].balanceIncomingDebet}</p></th>
                        <th><p>{store.globalTranslations[store.local].balanceIncomingCredit}</p></th>
                        <th><p>{store.globalTranslations[store.local].accrued}</p></th>
                        <th><p>{store.globalTranslations[store.local].recalculations}</p></th>
                        <th><p>{store.globalTranslations[store.local].subsidies}</p></th>
                        <th><p>{store.globalTranslations[store.local].chargeTotal}</p></th>
                        <th><p>{store.globalTranslations[store.local].payments}</p></th>
                        <th><p>{store.globalTranslations[store.local].balanceOutgoingDebet}</p></th>
                        <th><p>{store.globalTranslations[store.local].balanceOutgoingCredit}</p></th>
                    </tr>
                    {
                        this.props.mainContents.map(content => {
                            i++;
                            return (
                                <ContentBalance
                                    content={content}
                                    id={i}
                                    key={i + Math.random()}
                                    mode={this.props.mode}
                                />
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
        </div>)
    }
}