import React from 'react';
import logo from './logo.svg';
import './App.css';
import './css/materialize.min.css';
import './css/style.css';
import M from 'materialize-css';
import * as jQscripts from './js/jQscripts';

import Store from './js/store.js';
import adminHelpers from './js/scripts_admin';

import Preloader from "./shared/components/Preloader";
import ChangePasswordPage from "./app/components/changePasswordPage";
import ViewMainContents from "./app/components/viewMainContents";
import ViewBalance from "./app/components/viewBalance";
import ViewInfo from "./app/functions/viewInfo";
import ModalWindow from "./app/components/modalWindow";


const time = new Date();

const store = new Store();


function Navigate(props) {
    const classes = ['navigate-item ']

    if (props.navigate.active) {
        classes.push('active-nav')
    } else
        classes.push('de-active-nav')
    if (props.mode) {
        return (
            <li>
                <div className={classes.join(' ')}>
                    <a onClick={props.onClick}>(click)</a>
                    <p id={'toNavigate' + props.id} data-type={(props.mode) ? "globalTranslations" : ""}
                       data-name={(props.mode) ? "navigate" : ""}
                       data-name2={(props.mode) ? "" + props.navigate.key : ""}>
                        {store.globalTranslations[store.local].navigate[props.navigate.key]}
                    </p>
                    <span data-nav={(props.mode) ? ('navigate') : ""}
                          data-navid={(props.mode) ? ('toNavigate' + props.id) : ""} className={'editText'}></span>

                </div>
            </li>
        )//<span className={'changeVisible'}></span>
    }
    classes.push('waves-effect waves-light btn');
    return (
        <li>
            <a className={classes.join(' ')} onClick={props.onClick}>
                {store.globalTranslations[store.local].navigate[props.navigate.key]}
            </a>
        </li>
    )
}

function Language(props) {

    return (
        <a onClick={props.onClick}>
            <img style={{height: 20}} src={props.language.icon}/>
            <span style={{marginLeft: 8}}>{props.language.key}</span>
        </a>
    )
}

function Report(context) {
    return (
        <div className={'d_content z-depth-3'}>

            <div className={"modal-content"}>
                <h4>{store.globalTranslations[store.local].report}</h4>
                <p>{store.globalTranslations[store.local].fillFields}</p>
                <div className={"row"}>
                    <div className={"input-field col s12"}>
                        <input id="regCodeReport" type="text" className={"validate"}/>
                        <label htmlFor="regCodeReport">{store.globalTranslations[store.local].registrationCode}</label>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12">
                        <div className="row">
                            <div className="input-field col s12">
                                <input type="text" id="autocomplete-input-cities-report"
                                       className="autocomplete"
                                       onBlur={() => {
                                           setTimeout(() => {
                                               store.changeAddress(document.getElementById('autocomplete-input-cities-report'), true)
                                           }, 400);
                                       }}
                                       onKeyPress={(e) => {
                                           store.nextAddress(e, true)
                                       }}
                                       data-cur="cities" data-last="streets"/>
                                <label
                                    htmlFor="autocomplete-input-cities">{store.globalTranslations[store.local].city}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12">
                        <div className="row">
                            <div className="input-field col s12">
                                <input type="text" id="autocomplete-input-streets-report"
                                       className="autocomplete"
                                       onBlur={() => {
                                           setTimeout(() => {
                                               store.changeAddress(document.getElementById('autocomplete-input-streets-report'), true)
                                           }, 200);
                                       }}
                                       onKeyUp={(e) => {
                                           store.nextAddress(e, true)
                                       }}
                                       data-cur="streets" data-last="buildings" disabled/>
                                <label
                                    htmlFor="autocomplete-input-streets">{store.globalTranslations[store.local].street}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12">
                        <div className="row">
                            <div className="input-field col s12">
                                <input type="text" id="autocomplete-input-buildings-report"
                                       className="autocomplete"
                                       onBlur={() => {
                                           setTimeout(() => {
                                               store.changeAddress(document.getElementById('autocomplete-input-buildings-report'), true)
                                           }, 200);
                                       }}
                                       onKeyUp={(e) => {
                                           store.nextAddress(e, true)
                                       }}
                                       data-cur="buildings" data-last="apartments" disabled/>
                                <label
                                    htmlFor="autocomplete-input-buildings">{store.globalTranslations[store.local].home}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12">
                        <div className="row">
                            <div className="input-field col s12">
                                <input type="text" id="autocomplete-input-apartments-report"
                                       className="autocomplete"
                                       onBlur={() => {
                                           setTimeout(() => {
                                               store.changeAddress(document.getElementById('autocomplete-input-apartments-report'), true)
                                           }, 200);
                                       }}
                                       onKeyUp={(e) => {
                                           store.nextAddress(e, true)
                                       }}
                                       data-cur="apartments" data-last="" disabled/>
                                <label
                                    htmlFor="autocomplete-input-apartments">{store.globalTranslations[store.local].apartment}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <a href="#!"
                   className="modal-action modal-close waves-effect waves-green btn-flat login-btn">{store.globalTranslations[store.local].cancelBtn}</a>
                <a href="#!" className="modal-action waves-effect waves-light btn login-btn"
                   onClick={() => {
                       store.getReport(context)
                   }}>{store.globalTranslations[store.local].continueBtn}</a>
            </div>

        </div>
    );
}

class App extends React.Component {
    state = {
        isLoaded: false,
        header: {
            languages: [
                /*{
                    key: 'RU',
                    selected: true,
                    icon: 'images/icon/RU.png'
                }*/
                {
                    key: 'UA',
                    selected: true,
                    icon: 'images/icon/UA.png'
                }/*,
        {
          key: 'RU',
          selected: true,
          icon: 'images/icon/RU.png'
        },
          {
              key: 'ENG',
              selected: false,
              icon: 'images/icon/ENG.png'
          }*/
            ],
            logo: 'images/logo-best.png',
            logoHeingh: 57
        },
        navigates: [],
        mainContents: [{
            id: 0,
            content: '',
            html: '<div><h2 class="line-span">Упс...Щось пішло не так. Спробуйте будь ласка пізніше.</h2></div>'
        }],
        responseContent: {info: [], rep: ''},
        info: '',
        freezeComplite: false,
        balance: {year: time.getFullYear(), month: (time.getMonth()+1)},
        //eslint-disable-next-line
        mode: (typeof MODE !== 'undefined') ? MODE : null,
        login: null,
        uid: null,
        tabID: sessionStorage.tabID ? sessionStorage.tabID : sessionStorage.tabID = Math.random(),
        isNeedLoadContent: false
    };


    componentDidMount() {
        console.log("componentDidMount");


        // todo код ниже вызывает кучу вопросов
        {
            if (store.isset(store.globalTranslations[store.local])) {
                const languages = this.state.header.languages.concat();
                languages.forEach(function (language) {
                    language.selected = language.key === store.local;
                });

                switch (store.local) {
                    case 'RU':
                        document.title = 'ООО "УК "Ладис"';
                        break;
                    case 'UA':
                        document.title = 'ТОВ "УК "Ладіс"';
                        break;
                    case 'ENG':
                        document.title = 'ТОВ "УК "Ладіс"';
                        break;
                }
            }

        }

        // Проверка авторизации
        {
            let dataObj = {
                type: 'isAuth'
            };

            store.sendAjaxNew(dataObj, (data => {
                this.setState({login: data.isAuth})
            }));
        }

        // Получение данных для панели навигации
        {
            let dataObj = {
                type: 'getSiteData',
                name: 'navigates'
            };

            store.sendAjaxNew(dataObj, (data => {
                {
                    let navKey = store.getUrlParam("nav");
                    let flag = true;
                    const navigates = JSON.parse(data.navigates);

                    navigates.forEach(function (navigate) {
                        if (navigate.key === parseInt(navKey)) {
                            navigate.active = true;
                            flag = false;
                        } else navigate.active = false;
                    });
                    if (flag) navigates[0].active = true;

                    this.setState({navigates})
                }
                this.loadContent()
            }));
        }

    }

    /**
     * Вызывается сразу после обновления компонента
     */
    componentDidUpdate() {
        console.log('componentDidUpdate');
        try {
            for (const li of document.querySelectorAll(".editText")) {
                li.addEventListener("click", adminHelpers.editer);
            }

            for (const elem of document.querySelectorAll("select")) {
                if (!elem.classList.contains('UNselect')) {
                    const instance = M.FormSelect.init(elem);
                    M.FormSelect.getInstance(instance);
                }
            }

            jQscripts.getPagination('#table-id');

            if (store.needLoadRegistr) {
                store.loadRegistr(true);
                store.needLoadRegistr = false;
            }

            for (const el of document.querySelectorAll(".tabs")) {
                M.Tabs.init(el);
            }


            const balanceNode = document.getElementById('balanceNode');
            if (balanceNode) {
                console.log('balanceNode');
                this.state.responseContent.rep = balanceNode.innerHTML;
                this.state.responseContent.year = this.state.balance.year;
                this.state.responseContent.month = this.state.balance.textMonth;
                document.getElementById('toPrinter').href = "/balance?data=" + JSON.stringify(this.state.responseContent);
            }

            const phoneNum = document.getElementById('phoneNum');
            if (phoneNum && phoneNum.value === '') {
                phoneNum.value = store.phoneNum;
            }
        } catch (e) {
            console.error("cabinet/balance")
        }


        // были ли изменены данные, которые требует повторного запроса к серверу
        if (this.state.isNeedLoadContent)
            this.loadContent();

        // устанавливаем минимальную высоту контейнера в зависимости от размера окна браузера пользователя
        store.changeMainBoxHeight();
    }

    loadContent() {
        console.log('loadContent');
        const context = this;
        let dataObj = {
            type: 'getContent',
            href: '',
            lang: store.local
        };

        const navigates = this.state.navigates.concat();
        dataObj.href = navigates.find(c => c.active === true).href;

        // todo Код ниже является костылем.
        // Стоит добавить маркер для каждого из элементов объекта navigates и уже по нему рашать эту проблему
        {
            if (dataObj.href === "balance") {
                dataObj.href = "cabinet/balance";
                dataObj.type = 'cross-domain-request';
                dataObj.data = "year=" + this.state.balance.year + "&month=" + this.state.balance.month;
                dataObj.method = 'GET-auth';
            }

            if (dataObj.href === "contract" || dataObj.href === "tariff" || dataObj.href === "services") {
                dataObj.data = dataObj.href;
                dataObj.href = "cabinet/info";
                dataObj.type = 'cross-domain-request';
                dataObj.method = 'GET-auth';
            }
        }

        store.sendAjaxNew(dataObj, (data => {
            context.loadResponse(context, data.content, dataObj/*, navigates*/);
        }))

    }

    loadResponse(context, content, dataObj) {
        console.log('loadResponse');

        let resContent = [];
        if (content !== '') {
            switch (dataObj.href) {
                case 'cabinet/balance':
                    console.log('loadResponse cabinet/balance');
                    if (content === "-1") {
                        store.modalLogin();
                        M.toast({html: 'Для продовження повторіть авторизацію.', classes: 'toast-Error'});
                        return;
                    }

                    resContent = content.table;
                    context.state.info = content.info;
                    context.state.uid = content.user;

                    break;
                case 'Report':
                    if (content !== 'Need File-Key') {
                        console.log("!Need File-Key");
                        resContent = [{
                            id: 0,
                            html: '<div><div class="col s12" style="margin: -10px;"><ul class="tabs z-depth-1">' +
                                '        <li class="tab col s6"><a class="active" href="#page1">Фінансовий звіт</a></li>' +
                                '        <li class="tab col s6"><a href="#page2">Звіт за обсягами</a></li></ul>' +
                                '    </div><div class="col s12" id="page1">' + content + '</div><div class="col s12" id="page2"><h2 style="margin-top: 40px;">Сторінка на стадії розробки</h2></div></div>'
                        }];
                    } else {
                        console.warn("Need File-Key");
                        this.handleNavigate(5);
                    }
                    break;
                case 'cabinet/info': {
                    switch (dataObj.data) {
                        case 'contract':
                            console.log('contract');
                            resContent = [{
                                id: 1,
                                html: '<object data="/contracts/' + content.contractCode + '.pdf" type="application/pdf" width="100%" height="800px"/>'
                            }];
                            break;
                        case 'tariff':
                            console.log('tariff');
                            resContent = [{
                                id: 2,
                                html: '<object data="/structure/' + content.tarifCode + '.pdf" type="application/pdf" width="100%" height="800px"/>'
                            }];
                            break;
                        case 'services':
                            console.log('services');
                            resContent = [{
                                id: 3,
                                html: '<object data="/reports/' + content.periodicityCode + '.pdf" type="application/pdf" width="100%" height="800px"/>'
                            }];
                            break;
                    }

                }
                    break;

                default:
                    resContent = JSON.parse(content);
            }

            if (resContent === '' || resContent === null) {
                resContent = [{
                    id: 0,
                    content: '',
                    html: '<div><h2 class="line-span">Упс...Щось пішло не так. Спробуйте будь ласка пізніше.</h2></div>'
                }];
                // todo balance-error
                //navigates.find(c => c.active === true).href = "balance-error";
            }
        } else {
            resContent = [{id: 0, html: '<div><h2 class="line-span">Порожня сторінка</h2></div>'}];
        }

        context.setState({
            isLoaded: true,
            mainContents: resContent,
            isNeedLoadContent: false
        });
    }


    handleUpdateContent(href) {
        const year = document.getElementById('yearBalance').value;
        const month = document.getElementById('monthBalance').value;

        const balance = {
            year: year,
            month: month,
            textMonth: document.getElementById('monthBalance').options[month - 1].text
        };

        this.setState({balance, isNeedLoadContent: true});
    }

    handleNavigate(key) {
        const navigates = this.state.navigates.concat();
        let needUpdate = false;

        navigates.forEach(function (navigate) {
            if (navigate.key === key) {
                if (navigate.active !== true) needUpdate = true;
                navigate.active = true;
                store.setLocation('nav', key);
            } else navigate.active = false;
        });

        if (needUpdate)
            this.loadContent();
    }

    renderNavigateMenu() {
        return this.state.navigates.map(navigate => {
            if (navigate.visible)
                return (
                    <Navigate
                        navigate={navigate}
                        id={navigate.key}
                        key={navigate.key + Math.random()}
                        onClick={() => this.handleNavigate(navigate.key)}
                        mode={this.state.mode}
                    />
                )
        })
    }

    handleLanguageChange(key) {
        store.local = key;
        store.setLocation('lang', key);
        switch (store.local) {
            case 'RU':
                document.title = 'ООО "УК "Ладис"';
                break;
            case 'UA':
                document.title = 'ТОВ "УК "Ладіс"';
                break;
            case 'ENG':
                document.title = 'ТОВ "УК "Ладіс"';
                break;
        }
        this.loadContent();
        const languages = this.state.header.languages.concat();

        languages.forEach(function (language) {
            if (language.key === key)
                language.selected = true;
            else language.selected = false;
        });
    }

    renderLanguageSelected() {
        const languages = this.state.header.languages.concat();

        let curLanguage = languages.find(c => c.selected === true);

        if(!curLanguage)
            curLanguage = languages[0];

        return (
            <div className="dropdown">
                <div className="dropbtn">
                    <img style={{height: 35}} src={curLanguage.icon}/>
                    <p>{curLanguage.key}</p>
                </div>
                <div className="dropdown-content dropPos">{
                    this.state.header.languages.map(language => {
                        return (
                            <Language
                                language={language}
                                key={language.key + Math.random()}
                                onClick={() => this.handleLanguageChange(language.key)}
                            />
                        )
                    })
                }
                </div>
            </div>
        )
    }

    saveContent(id) {
        console.log('saveContent');


        const context = this;
        let dataObj = {
            id: (id > 0 ? id : 0),
            href: '',
            lang: store.local,
            html: document.getElementById('content').innerHTML
        };

        const navigates = this.state.navigates.concat();
        dataObj.href = navigates.find(c => c.active === true).href;
        store.sendAjax(dataObj, (content => {
            if (content === 'Good')
                context.loadContent();
            else {
                context.state.mainContents = [{
                    id: '0',
                    html: '<div><h2 class="line-span">Ошибка сохранения. Обновите сраницу и повторите попытку.</h2></div>'
                }];//todo перенести сообщения в файл локализации
                context.setState({navigates});
            }
        }), 'setContent')
    }

    addNewContent() {
        console.log('addNewContent');
        store.smoothScrollTo(0, 500);
        setTimeout(() => {
            const context = this;
            let dataObj = {
                id: 0,
                href: '',
                lang: store.local
            };
            const navigates = this.state.navigates.concat();
            dataObj.href = navigates.find(c => c.active === true).href;
            store.sendAjax(dataObj, (content => {
                if (content === 'Good')
                    context.loadContent();
                else {
                    context.state.mainContents = [{
                        id: '0',
                        html: '<div><h2 class="line-span">Ошибка создания. Обновите сраницу и повторите попытку.</h2></div>'
                    }]; //todo перенести сообщения в файл локализации

                    context.setState({navigates});
                }
            }), 'newContent');
        }, 498);
    }

    renderMainContents() {
        console.log("renderMainContents start");

        const navigates = this.state.navigates.concat();
        const href = navigates.find(c => c.active === true).href;


        switch (href) {
            case 'balance': {
                console.log("render ViewInfo and ViewBalance,  cabinet/balance");

                this.state.balance.textMonth = store.globalTranslations[store.local].monthName[this.state.balance.month - 1];


                let htmlInfo;
                [htmlInfo, this.state.responseContent.info] = ViewInfo({info: this.state.info, mode: this.state.mode});

                const htmlBalance = <ViewBalance balance={this.state.balance} mainContents={this.state.mainContents}
                             mode={this.state.mode} handleUpdateContent={(props)=>{this.handleUpdateContent(props)}} />
                return (<div>
                    {
                        htmlInfo
                    }
                    {
                        htmlBalance
                    }
                </div>);
            }
            case 'pass': {
                return (<ChangePasswordPage/>);
            }
            default: {
                return (<div>
                    {
                        this.state.mainContents.map(content => {
                            return (
                                <ViewMainContents
                                    content={content}
                                    id={content.id}
                                    key={content.id + Math.random()}
                                    href={href}
                                    mode={this.state.mode}
                                    lastEl={this.state.mainContents[this.state.mainContents.length - 1]}
                                    onClose={() => this.loadContent()}
                                    onSave={() => this.saveContent(content.id)}
                                />
                            )
                        })
                    }
                    {
                        this.state.mode ? <div className="waves-effect waves-light btn backPlus"
                                               onClick={() => this.addNewContent()}/> : ''
                    }
                </div>);
            }
        }
    }

    render() {
        console.log('render', this.state);
        if (!this.state.isLoaded) {
            return (<Preloader/>)
        } else {

            const urlPay = "https://next.privat24.ua/payments/form/{\"companyID\":\"1225048\",\"form\":{\"query\":\"40872929\",\"queryId\":\"3367864\"}}";

            return (
                <div>
                    <ModalWindow isLogin={this.state.login}/>

                    <div id="headerDiv">
                        <div className="item-header3">
                            <div className={"row"}>
                                <div className="col s2 topP">
                                    <p className={((this.state.mode) ? "editText" : "") + ""}
                                       data-type={(this.state.mode) ? "globalTranslations" : ""}
                                       data-name={(this.state.mode) ? "topText1" : ""}>{store.globalTranslations[store.local].topText1}</p>
                                </div>
                                <div className="col s4 topP">
                                    <p className={((this.state.mode) ? "editText" : "") + ""}
                                       data-type={(this.state.mode) ? "globalTranslations" : ""}
                                       data-name={(this.state.mode) ? "topText2" : ""}>{store.globalTranslations[store.local].topText2}</p>
                                </div>
                                <div className="col s3">
                                    {this.renderLanguageSelected()}
                                </div>
                                <div className="col s3">
                                    {(!this.state.login ?
                                            <div id="topBox3" className="item-header4">
                                                <a className={"waves-effect waves-light btn active-nav btnTop"}
                                                   onClick={() => {
                                                       console.log("pre modalLogin");
                                                       store.modalLogin()
                                                   }}>{store.globalTranslations[store.local].personalAccount}</a>

                                            </div> :
                                            <div id="topBox3" className="item-header4">
                                                <a className={"waves-effect waves-light btn active-nav btnTop"}
                                                   onClick={() => {
                                                       store.loginExit()
                                                   }}>{store.globalTranslations[store.local].exit}</a>

                                            </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="topDiv">
                        <div id="topBox1" className="item-header3 header-margin">
                            <div className={"row"}>
                                <div className="col">
                                    <img src={this.state.header.logo} style={{height: this.state.header.logoHeingh}}/>
                                </div>
                                <div className="col s6">
                                    <h1 className={((this.state.mode) ? "editText" : "") + " col siteName"}
                                        data-type={(this.state.mode) ? "globalTranslations" : ""}
                                        data-name={(this.state.mode) ? "titleName" : ""}>{store.globalTranslations[store.local].titleName}</h1>
                                </div>
                                <div className="col s4 divBtnPay">
                                    <a className={"waves-effect waves-light btn btnPay"}
                                       href={urlPay}
                                       target="_blank">
                                        Сплатити за послугу
                                        <p className={"pBtnPay"}>(без реєстрації)</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="mainDiv">
                        <div id="leftBox" className={"item-left"}>
                            <ul className={"navigate z-depth-3"}>
                                {this.renderNavigateMenu()}
                                {
                                    //this.state.mode?<li className={'waves-effect waves-light btn backPlus'}></li>:''
                                }
                            </ul>
                        </div>
                        <div id="mainBox" className="item-center">
                            {this.renderMainContents()}
                        </div>
                    </div>

                    <footer className="page-footer">
                        <div className="footer-copyright">
                            <div className={((this.state.mode) ? "editText" : "") + " col container"}
                                 data-type={(this.state.mode) ? "globalTranslations" : ""}
                                 data-name={(this.state.mode) ? "copyrightText" : ""}>
                                {store.globalTranslations[store.local].copyrightText}
                            </div>
                        </div>
                    </footer>

                    <div id="back"/>
                </div>
            );
        }
    }
}

export default App;
