//import $ from 'jquery';
import React from 'react';
import Store from './store.js';

const store = new Store();

const adminHelpers = {
    editer: function (e) {
        console.log('click');
        let t = e.target || e.srcElement;
        let elm_name = t.tagName.toLowerCase();

        if (elm_name == 'input') return false;

        if (this.getAttribute('data-nav')) {
            const element = document.getElementById(this.getAttribute('data-navid'));
            const val = element.innerHTML;
            const newInput = document.createElement("input");
            newInput.type = "text";
            newInput.style.width = "100%"; // set the CSS class
            newInput.value = val;
            newInput.id = "edit";
            newInput.onkeydown = (e) => {
                adminHelpers.checkEnter(e)
            };
            element.innerHTML = '';
            element.appendChild(newInput);
        } else {
            let val = this.innerHTML;
            const newInput2 = document.createElement("input");
            newInput2.type = "text";
            newInput2.style.width = "100%"; // set the CSS class
            newInput2.value = val;
            newInput2.id = "edit";
            newInput2.onkeydown = (e) => {
                adminHelpers.checkEnter(e)
            };
            this.innerHTML = '';
            this.appendChild(newInput2);
        }
        let editElement = document.getElementById("edit");
        editElement.focus();
        editElement.onblur = (function (sInput) {
            return function () {
                console.log("попал");
                let parrent = sInput.parentElement;
                if (parrent.hasAttribute('data-name')) {
                    let value = sInput.value;
                    //if(parrent.getAttribute('data-name')=='full_cost'){value = value.replace(/ /g,'');}
                    console.log(parrent.getAttribute('data-name') + "," + value);
                    adminHelpers.save(parrent.getAttribute('data-type'), parrent.getAttribute('data-name'), value, parrent.getAttribute('data-name2'));
                }
                parrent.innerHTML = sInput.value;
            }
        })(editElement);
    },
    checkEnter: function (event) {
        if (event.key === 'Enter') { //если это Enter
            try {
                document.getElementById("edit").blur(); //снимаем фокус с поля ввода
            } catch (e) {
            }
        }
    },
    save: function (type, name, value, name2) {
        console.log("save");
        console.log(type, name, value, name2);
        console.log(store.globalTranslations);
        switch (type) {
            case 'globalTranslations':
                if (!name2)
                    store.globalTranslations[store.local][name] = value;
                else
                    store.globalTranslations[store.local][name][name2] = value;
                let dataObj = {
                    type: type,
                    object: JSON.stringify(store.globalTranslations)
                };
                console.log(store.globalTranslations);
                this.sendAjaxAdmin(dataObj);
                break;
        }
    },
    sendAjaxAdmin: function (dataObj) {
        dataObj.tabID = sessionStorage.tabID;

        let ajax = new XMLHttpRequest();
        ajax.open("POST", "php/setJSON.php", true);
        ajax.setRequestHeader('accept', 'application/json');
        ajax.setRequestHeader("content-type", "application/json");
        ajax.send(JSON.stringify(dataObj));
        ajax.onreadystatechange = function () {
            if (ajax.readyState === 4 && ajax.status === 200) {
                console.log(ajax.responseText);
            }
        }
    },
    startEditer: function () {
        console.log('startEditer');
        try {
            //eslint-disable-next-line
            $('#content').redactor({
                imageUpload: 'redactor/image_upload.php',
                fileUpload: 'redactor/file_upload.php',
                imageGetJson: 'redactor/data.txt'
            });
        } catch (e) {
            console.log(e);
        }
    },
    changeVisibleContent: function (id, visible) {
        let dataObj = {
            id: id,
            visible: visible
        };

        store.sendAjax(dataObj, (() => {
        }), 'setContent')
    }
}

export default adminHelpers;
