import React from "react";
import Store from "../../js/store";
import EditViewMainContent from "../functions/editViewMainContent"

const store = new Store();

export default class ViewMainContents extends React.Component {
    constructor(props) {
        super();

    }

    render() {
        const props = this.props;

        if (typeof (props.content.html) === "object") {
            return (props.content.html);
        } else {
            if (props.mode && store.isset(props.lastEl) && props.lastEl.length > 0 && store.isset(props.lastEl[0].visible)) {
                if (!Array.isArray(props.content)) {
                    return (<div id={'toRemove' + props.id}>
                        {props.mode ? EditViewMainContent(props) : ''}
                        <div className={'d_content z-depth-3'} data-content={"content" + props.id}>
                            <div dangerouslySetInnerHTML={{__html: props.content.html}}/>
                        </div>
                    </div>)
                }
                return (<div/>);
            } else {
                return (
                    <div id={'toRemove' + props.id}>
                        <div className={'d_content z-depth-3'} data-content={"content" + props.id}>
                            <div dangerouslySetInnerHTML={{__html: props.content.html}}/>
                        </div>
                    </div>)
            }

        }
    }
}