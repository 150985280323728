import React from "react";

export default function ContentBalance(props) {

    try {
        return (
            <tr>
                <td>{props.content.srv.name}</td>
                <td>{props.content.balanceIn.debet}</td>
                <td>{props.content.balanceIn.credit}</td>
                <td>{props.content.charge.amount}</td>
                <td>{props.content.recalculation.amount}</td>
                <td>{props.content.subsidy.amount}</td>
                <td>{props.content.chargeTotal.amount}</td>
                <td>{props.content.payment.amount}</td>
                {/*<td>{props.content.relief.amount}</td>*/}
                <td>{props.content.balanceOut.debet}</td>
                <td>{props.content.balanceOut.credit}</td>
            </tr>);
    } catch (e) {
        return (<tr>
            <td>Ошибка получения данных</td>
            <td>Повторите авторизацию.</td>
        </tr>);
    }
}