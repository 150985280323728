import React from "react";
import Store from '../../js/store.js';

const store = new Store();

export default class ModalWindow extends React.Component {
    constructor(props) {
        super(props);

        this.onCancelClick = this.handleCancelClick.bind(this);
    }

    handleCancelClick(event) {
        if (this.props.isLogin)
            store.loginExit();
    }

    loginBlock() {
        return (
            <div className={"flip-front"} id="loginDiv">
                <div className={"modal-content"}>
                    <h4>{store.globalTranslations[store.local].authorization}</h4>
                    <div className={"input-field col s6"}>
                        <input id="login" type="text" className="validate"
                               onKeyPress={(e) => {
                                   if (e.key === 'Enter') document.getElementById("password").focus();
                               }}/>
                        <label htmlFor="login">{store.globalTranslations[store.local].login}</label>
                    </div>
                    <div className="input-field col s12">
                        <input id="password" type="password" className="validate"
                               onKeyPress={(e) => {
                                   if (e.key === 'Enter') store.loginCheck()
                               }}/>
                        <label htmlFor="password">{store.globalTranslations[store.local].password}</label>
                    </div>
                    <a className="link-btn" onClick={() => {
                        store.loadRegistr(false);
                    }}>{store.globalTranslations[store.local].registration}</a>
                </div>
                <div className={"modal-footer"}>
                    <a href="#!"
                       className={"modal-action modal-close waves-effect waves-green btn-flat login-btn"}
                       onClick={this.onCancelClick}
                    >{store.globalTranslations[store.local].cancelBtn}</a>
                    <a href="#!" className={"modal-action waves-effect waves-light btn login-btn"}
                       onClick={(context) => {
                           store.loginCheck(context)
                       }}>{store.globalTranslations[store.local].enterSystem}</a>
                </div>
            </div>
        );
    }

    registrationBlock() {
        return (
            <div className={"flip-back"} id="regDiv">
                <div className={"modal-content"}>
                    <h4>{store.globalTranslations[store.local].registration}</h4>
                    <div className={"row"}>
                        <div className={"input-field col s6"}>
                            <input id="loginNew" type="text" className={"validate"}/>
                            <label
                                htmlFor="loginNew">{store.globalTranslations[store.local].personalAccNum}</label>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"input-field col s12"}>
                            <input id="phoneNum" type="text" className={"validate"} onChange={(e) => {
                                document.getElementById('phoneNum').value = ("+" + (e.target.value.replace(/\D/g, '').substr(0, 12)));
                            }}/>
                            <label htmlFor="phoneNum"
                                   className={"active"}>{store.globalTranslations[store.local].phoneNum}</label>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"input-field col s12"}>
                            <input id="regCode" type="text" className={"validate"}/>
                            <label
                                htmlFor="regCode">{store.globalTranslations[store.local].registrationCode}</label>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"input-field col s12"}>
                            <input id="email" type="email" className={"validate"}/>
                            <label htmlFor="email">Email</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12">
                            <div className="row">
                                <div className="input-field col s12">
                                    <input type="text" id="autocomplete-input-cities"
                                           /*value={"м. Краматорськ"}*/
                                           className={"autocomplete selected"}
                                           onBlur={() => {
                                               setTimeout(() => {
                                                   store.changeAddress(document.getElementById('autocomplete-input-cities'))
                                               }, 400);
                                           }}
                                           onKeyPress={(e) => {
                                               store.nextAddress(e)
                                           }}
                                           data-cur="cities" data-last="streets"/>
                                    <label
                                        htmlFor="autocomplete-input-cities">{store.globalTranslations[store.local].city}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12">
                            <div className="row">
                                <div className="input-field col s12">
                                    <input type="text" id="autocomplete-input-streets"
                                           className="autocomplete"
                                           onBlur={() => {
                                               setTimeout(() => {
                                                   store.changeAddress(document.getElementById('autocomplete-input-streets'))
                                               }, 200);
                                           }}
                                           onKeyUp={(e) => {
                                               store.nextAddress(e)
                                           }}
                                           data-cur="streets" data-last="buildings" disabled/>
                                    <label
                                        htmlFor="autocomplete-input-streets">{store.globalTranslations[store.local].street}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12">
                            <div className="row">
                                <div className="input-field col s12">
                                    <input type="text" id="autocomplete-input-buildings"
                                           className="autocomplete"
                                           onBlur={() => {
                                               setTimeout(() => {
                                                   store.changeAddress(document.getElementById('autocomplete-input-buildings'))
                                               }, 200);
                                           }}
                                           onKeyUp={(e) => {
                                               store.nextAddress(e)
                                           }}
                                           data-cur="buildings" data-last="apartments" disabled/>
                                    <label
                                        htmlFor="autocomplete-input-buildings">{store.globalTranslations[store.local].home}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12">
                            <div className="row">
                                <div className="input-field col s12">
                                    <input type="text" id="autocomplete-input-apartments"
                                           className="autocomplete"
                                           onBlur={() => {
                                               setTimeout(() => {
                                                   store.changeAddress(document.getElementById('autocomplete-input-apartments'))
                                               }, 200);
                                           }}
                                           onKeyUp={(e) => {
                                               store.nextAddress(e)
                                           }}
                                           data-cur="apartments" data-last="" disabled/>
                                    <label
                                        htmlFor="autocomplete-input-apartments">{store.globalTranslations[store.local].apartment}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a className="link-btn"
                       onClick={() => {
                           document.getElementById('regDiv').style.display = 'none';
                           document.getElementById('loginDiv').style.display = 'block';
                       }}>{store.globalTranslations[store.local].authorization}</a>
                </div>
                <div className="modal-footer">
                    <a href="#!"
                       className="modal-action modal-close waves-effect waves-green btn-flat login-btn">{store.globalTranslations[store.local].cancelBtn}</a>
                    <a href="#!" className="modal-action waves-effect waves-light btn login-btn"
                       onClick={() => {
                           store.goRegistr()
                       }}>{store.globalTranslations[store.local].continueBtn}</a>
                </div>
            </div>
        );
    }

    successRegistrationBlock() {
        return (
            <div className={"flip-back"} id="successRegDiv">
                <div className={"modal-content"}>
                    <h4>{store.globalTranslations[store.local].thankForReg}</h4>
                    <p>{store.globalTranslations[store.local].successMessage}</p>
                </div>
                <div className="modal-footer">
                    <a href="#!"
                       className="modal-action modal-close waves-effect waves-light btn login-btn">Готово</a>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div id="modal" className={"modal"}>

                {
                    this.loginBlock()
                }
                {
                    this.registrationBlock()
                }
                {
                    this.successRegistrationBlock()
                }

            </div>
        )
    }
}