import React from "react";
import adminHelpers from "../../js/scripts_admin";


export default function MainContentsEdit(props) {
    const visible = (props.lastEl.find(c => parseInt(c.id) === parseInt(props.id)).visible) ? "redactor_btn_visible" : "redactor_btn_unvisible";
    return (
        <div>
            <div id={'toClose' + props.id} style={{display: 'none'}}>
                <a className={'redactor_btn redactor_btn_cancel btn waves-effect waves-light'} onClick={props.onClose}>
                </a>
                <a className={'redactor_btn redactor_btn_save btn waves-effect waves-light'} onClick={props.onSave}>
                </a>
            </div>
            <a className={'redactor_btn redactor_btn_edit btn waves-effect waves-light'} id={'toEdit' + props.id}
               onClick={() => {
                   document.getElementById('toEdit' + props.id).remove();
                   document.getElementById('toClose' + props.id).style.display = 'block';
                   document.querySelector('.d_content[data-content="content' + props.id + '"]').id = 'content';
                   console.log('PREstartEditer');
                   adminHelpers.startEditer();
               }}>
            </a>
            <a className={'redactor_btn ' + visible + ' btn waves-effect waves-light'} id={'toVisible' + props.id}
               onClick={() => {
                   const el = document.getElementById('toVisible' + props.id);
                   if (el.classList.contains('redactor_btn_unvisible')) {
                       el.classList.remove("redactor_btn_unvisible");
                       el.classList.add("redactor_btn_visible");
                       adminHelpers.changeVisibleContent(props.id, true);
                   } else {
                       el.classList.remove("redactor_btn_visible");
                       el.classList.add("redactor_btn_unvisible");
                       adminHelpers.changeVisibleContent(props.id, false);
                   }
               }}>
            </a>
        </div>
    )
}